<template>
  <v-sheet
      :class="[{'fill-height' : !SM}]"
      color="white"
      class="d-flex  justify-center align-center "
      style="position: relative"
  >
    <v-row class="no-gutters fill-height"
    >
      <v-col v-if="!SM" cols="12" md="6">
        <v-sheet :color="wsLIGHTCARD" class="fill-height d-flex align-center justify-center">
          <v-img
              src="/library/img/home/contacts.png"
              alt="WeStudy Contacts"
              max-height="500"
              max-width="500"
          />
        </v-sheet>
      </v-col>
      <v-col cols="12" md="6" class="px-4">
        <v-sheet color="white" class="fill-height justify-center" :class="!SM ? 'd-flex align-center py-16' : 'pa-6 px-3'">

          <div>
            <h1 :style="`color : ${wsDARKER}`" >{{ $t('OurContacts') }}</h1>
            <h4 class="mr-3 mt-3" :style="`color : ${wsDARKER}`" style="font-size: 16px;line-height: 30px">{{ $t('Address') }}:</h4>
            <h4 class="mr-3" :style="`color : ${wsDARKER}`" style="font-size: 16px; font-weight: 400; line-height: 30px">{{ $t('homepage.address') }}🇺🇦</h4>
            <h4 class="mr-3 mt-3" :style="`color : ${wsDARKER}`" style="font-size: 16px;  line-height: 30px">{{ $t('Phone') }}:</h4>
            <h4 class="mr-3" :style="`color : ${wsDARKER}`" style="font-size: 16px; font-weight: 400; line-height: 30px">+38(063) 964 69 04</h4>
            <h4 class="mr-3" :style="`color : ${wsDARKER}`" style="font-size: 16px; font-weight: 400; line-height: 30px">+38(063) 159 63 36</h4>
            <h4 class="mr-3 mt-3" :style="`color : ${wsDARKER}`" style="font-size: 16px;line-height: 30px">Email:</h4>
            <h4 class="mr-3 " :style="`color : ${wsDARKER}`" style="font-size: 16px; font-weight: 400; line-height: 30px">support@westudy.ua</h4>

            <ws-button
                @click="$store.state.homepage.displayContactForm = true"
                label="homepage.sections.consultation.order"
                class="mt-6"
                :block="SM"
            />


          </div>


        </v-sheet>
      </v-col>
    </v-row>

  </v-sheet>
</template>

<script>
export default {
  name: "HomeContacts"
}
</script>

<style scoped>

</style>